import {NgModule} from '@angular/core';
import {ChatItemComponent} from './components/chat-item/chat-item.component';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FromNowPipe} from './pipes/from-now.pipe';
import {CreateNewConversationComponent} from './components/create-new-conversation/create-new-conversation.component';
import {SearchComponent} from './components/search/search.component';
import {SystemStatusComponent} from './components/system-status/system-status.component';
import {ListItemComponent} from './components/list-item/list-item.component';
import {TranslateModule} from '@ngx-translate/core';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {ScrollingModule as ExperimentalScrollingModule} from '@angular/cdk-experimental/scrolling';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {EmojiModule} from '@ctrl/ngx-emoji-mart/ngx-emoji';
import {ChatboxComponent} from './components/chatbox/chatbox.component';
import {MessageComponent} from './components/message/message.component';
import {UtcDatePipe} from './pipes/utc-date.pipe';
import {ReverseScrollComponent} from './components/reverse-scroll/reverse-scroll.component';
import {TooltipDirective} from './directives/tooltip.directive';
import { provideHttpClient, withInterceptorsFromDi, HttpClientModule } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ChatTypeComponent } from './components/chat-type/chat-type.component';
import {AddMembersComponent} from './components/add-members/add-members.component';
import {ConversationHeaderComponent} from './components/conversation-header/conversation-header.component';
import { HelpCenterComponent } from './components/help-center/help-center.component';

@NgModule({ 
    declarations: [
        ChatItemComponent,
        FromNowPipe,
        CreateNewConversationComponent,
        SearchComponent,
        SystemStatusComponent,
        ListItemComponent,
        ChatboxComponent,
        MessageComponent,
        UtcDatePipe,
        ReverseScrollComponent,
        TooltipDirective,
        ChatTypeComponent,
        AddMembersComponent,
        ConversationHeaderComponent,
        HelpCenterComponent
    ],
    exports: [
        ChatItemComponent,
        CommonModule,
        NgbModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        ReactiveFormsModule,
        FromNowPipe,
        CreateNewConversationComponent,
        SearchComponent,
        FormsModule,
        SystemStatusComponent,
        TranslateModule,
        ScrollingModule,
        ExperimentalScrollingModule,
        ChatboxComponent,
        MessageComponent,
        ReverseScrollComponent,
        TooltipDirective,
        HttpClientModule,
        ChatTypeComponent,
        AddMembersComponent,
        ConversationHeaderComponent,
        HelpCenterComponent
    ], imports: [CommonModule,
        NgbModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        EmojiModule,
        PickerModule,
        ScrollingModule,
        ExperimentalScrollingModule,
        MatTooltipModule,
        MatCheckboxModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class SharedModule {

}
