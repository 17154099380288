import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {endpoints} from '../constants/endpoints';
import {ApiResponseList} from '../models/responses/api.response';
import {HelpCategoryResponse} from '../models/responses/help-category.response';
import {map} from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class HelpService {
  private openPopUp = new BehaviorSubject('');
  currentPopupState = this.openPopUp.asObservable();
  constructor(private httpClient: HttpClient) {
  }

  search(search: string, page = 0) {
    const offset = page * 10;
    const url = endpoints.helpCategories + `?query=["title", ${search}, "like"]&limit=10&offset=${offset}`;
    return this.httpClient.get<ApiResponseList<HelpCategoryResponse>>(url);
  }

  changeMessage(message: any) {
    this.openPopUp.next(message)
  }
}
