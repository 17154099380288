import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {AuthService} from './services/auth.service';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import {TokenInterceptor} from './interceptors/token.interceptor';
import {SharedModule} from './modules/shared/shared.module';
import {TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import localeDE from '@angular/common/locales/de';
import {registerLocaleData} from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

registerLocaleData(localeDE, 'de');

// export function initToken(authService: AuthService) {
//   return () => new Promise(resolve => {
//     authService.setToken();
//     resolve();
//   });
// }

// export function initUser(authService: AuthService) {
//   return () => new Promise(resolve => {
//     authService.setUser().then(() => resolve());
//     authService.pollUserInfo();
//   });
// }

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgbModule
  ],
  providers: [
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initToken,
    //   deps: [AuthService],
    //   multi: true
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initUser,
    //   deps: [AuthService],
    //   multi: true
    // },
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
